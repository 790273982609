@media print {
  .prhdn {
    display: none !important;
    visibility: hidden;
  }
  .resultPagePrint {
    padding: 3rem 4rem;
  }
}

.nosel {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dft-editor {
  padding: 1rem;
  height: 500px;
  overflow: scroll-y;
}
